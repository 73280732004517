<template>
    <div class="card mb-3">
        <div class="card-body">
            <div class="row">
                <div class="col-sm-1">
                    <img :src="imageLogo" alt="logo" width="50">
                </div>
                <div class="col-sm-7">
                    <div class="font-weight-bold" style="font-size: 13px">PEMERINTAH PROVINSI SUMATERA UTARA</div>
                    <div class="font-weight-bold" style="font-size: 11px">BADAN PENGELOLAAN PAJAK DAN RETRIBUSI DAERAH</div>
                    <div class="font-weight-bold" style="font-size: 9px">BIDANG PAJAK AIR, PAJAK BAHAN BAKAR KENDARAAN BERMOTOR DAN PAJAK ROKOK</div>
                    <div style="font-size: 9px" class="font-weight-bold text-muted"><i class="fas fa-map-marker-alt"></i> Jl. Sisingamangaraja Km 5,5 Medan</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import LogoSumut from '@/assets/images/logoSumut.png'

export default {
    props: {
        detail: Object
    },
    data () {
        return {
            imageLogo: LogoSumut
        }
    }
}
</script>